export interface WithIdName {
  id: number
  name: string
}

export interface WithIdLink {
  id: number
  link: string
}

export interface Paginated<T> {
  total: number
  items: T[]
}

export interface PaginatedWithTotalInfo<T> {
  total: number
  dailyTotal: number
  weeklyTotal: number
  items: T[]
}

export interface PaginatedWithBoosting<T> {
  filterTotal: number
  boostTotal: number
  items: T[]
}

export interface TotalWithItem<T> {
  total: number
  item: T
}

export interface BoostingWithItem<T> {
  filterTotal: number
  boostTotal: number
  item: T
}

export interface PrivateInfoDetectedResponse {
  hasPhone: boolean
  hasEmail: boolean
  hasLink: boolean
  isLinkAdded?: boolean
}

export interface PositionType extends WithIdNameParentId {
  priority: number
  isDeprecated: boolean
}

export interface WithIdNameParentId extends WithIdName {
  parentId: NumberOrNull
}

export interface WithIdNameNullableParentId extends WithIdName {
  parentId?: NumberOrNull
}

export type CareerType = WithIdName

export type TechStack = WithIdName

export type ServiceArea = WithIdName

export interface TechStackWithPositionTypes extends TechStack {
  positionTypes?: number[]
}

export interface Location extends WithIdNameParentId {
  priority: number
  isSeoul: boolean
  isRemote: boolean
}

export interface FundingRound extends WithIdName {
  devScoutDesc: string
}

export interface SchoolGroup extends WithIdName {
  description: string | null
}

export interface Banner {
  id: number
  desktop_img: string
  desktop_url: string
  mobile_img: string
  mobile_url: string
}

export interface User {
  id: number
  name: string
  email: string
  phone: string
  image: string
  experience: number
  interestedPositionTypes: PositionType[] // priority, isDeprecated 없음
  interestedTechStacks: TechStack[]
  newMessageCount: number
  newInterestedStartupCount: number
  isActive: boolean
  isBirthYearOpen: boolean
  birthYear: string | null
}

export interface SearchedStartup extends WithIdName {
  thumbnail: string | null
}

export interface AdminUser {
  id: number
  name: string
  image: string
}

export interface Startup extends WithIdName {
  thumbnail: string
  briefIntro: string //간단소개
  hasPt: boolean
  positionTypes: string[]
}

export interface StartupDetailForProposal extends WithIdName {
  thumbnail?: string
  briefIntro: string
  fundingRound: string // 투자 단계
  accumulatedFunding: number // 누적 투자 유치액
  recentFundingDate: string // 최근 투자 유치 날짜
  memberCount: number
  devCount: number
  hasCto: boolean
  whatKindCompany: string // 어떤 회사인가요?
  curOutcome: string // 현재까지 성과
  members: StartupMember[]
  reasonForInvesting: string // 투자받은 이유
  images: string[]
}

export interface StartupDetail extends StartupDetailForProposal {
  thumbnail: string
  tags: string[]
  effortForDev: string
  solvingProblem: string // 해결하는 문제
  culture: string
  howToStartup: string // 어떻게 창업하셨나요?
  pitch: string | null
  pt: string | null
  vision: string // 미래, 비전이 궁금해요
  lookForDev: string // 어떤 분을 찾고 계신가요?
  compensation: string // 보상
  welfare: string // 복지
  location: Location // 규격화된 위치 따로 받기 ex) 서울시 강남구 ~~~~ => 서울 강남
  address: string
  positions: WholeStartupPosition[]
  isInterested: boolean
  ptRequest: number
  isPtRequested: boolean // 사용자 요청 여부
  createdAt: string
}

export interface MatchedStartup extends WithIdName {
  thumbnail: string
  briefIntro: string
  memberCount: number
  devCount: number
  hasCto: boolean
  fundingRound: string | null
  accumulatedFunding: number
  recentFundingDate: string | null
}

export interface StartupMember {
  id?: number
  name: string
  role: string
  career: string | null
  intro: string
}

export interface PartialStartupPosition extends WithIdName {
  careerType: string
  experienceRange: Range | null
  techStacks: string[]
  publishedAt: string // 포지션 공개처리 일시
  createdAt: string // 이걸로 new 인지 판단
  isInterested: boolean
  averageReplyPeriod: NumberOrNull
  isSincerityReview: boolean
}

export interface WholeStartupPosition extends PartialStartupPosition {
  preferred: string
  hiringProcess: string
  qualification: string
  task: string
  hasSupervisor: boolean
  expectationKeywords: WithIdName[]
  positionTypes: PositionType[]
}

export interface StartupSimple extends WithIdName {
  thumbnail: string
  tags: string[]
  briefIntro: string
  location: string
  memberCount: number
  devCount: number
}

export interface StartupForSearch extends StartupSimple {
  pt: string | null
  ptRequest: number
  isPtRequested: boolean
  pitch: string | null
  isNew: boolean
  viewedMeAt: string | null
  savedMeAt: string | null
  createdAt: string
}

export interface UserApplicationForPosition {
  isPossibleApply: boolean
  applyAvailableDate: string | null
  lastApplication: {
    resumeAcceptedAt: string | null
    acceptedAt: string | null
  } | null
  hasTempApplication: boolean
}

export interface StartupPositionForSearch extends PartialStartupPosition {
  startup: StartupForSearch
  userApplication: UserApplicationForPosition
}

export interface StartupPositionForSearchNoRequirePosition
  extends Partial<PartialStartupPosition> {
  startup: StartupForSearch
  userApplication: UserApplicationForPosition | null // 이때는 포지션이 없을 수도 있으므로 예외적으로 null 가능
}

export interface StartupPositionDetail extends WholeStartupPosition {
  startup: StartupSimple
  userApplication: UserApplicationForPosition
  updatedAt: string
  averageReplyPeriod: NumberOrNull
  isSincerityReview: boolean
}

export interface BaseInfo {
  banners: Banner[]
  careerTypes: CareerType[]
  positionTypes: PositionType[]
  techStacks: TechStackWithPositionTypes[]
  locations: Location[]
  rounds: FundingRound[]
  idpCertificates: IdpCertificate[]
}

export interface StartupSearchingReqParams extends PaginationParams {
  searchQuery?: string
  isPublished?: boolean
}

export interface StartupPositionFilterParams {
  parentPositionTypes?: number[] // 앞으로 확장성을 위해 배열이지만, 지금은 id 한개만 담음
  positionTypes?: number[] // id
  careerTypes?: number[] // id
  locations?: number[]
  parentLocations?: number[]
  techStacks?: number[]
}

export interface StartupPositionReqParams extends StartupPositionFilterParams {
  offset: number
  limit: number
  orderBy?: StartupPositionOrderByType
}

export type StartupPositionOrderByType = 'replyPeriod' | '-updatedAt'

export interface LoginResult {
  needInquiry: boolean
  fromSite: string
  tempToken?: string
  name?: string
  email?: string
  phone?: string
  image?: string
}

export interface StartupPage {
  curr: StartupDetail
}

export interface Envelope<T = unknown> {
  status: number
  data?: T
  msg?: string
}

export interface ApplicationStatus {
  id: number
  name: ApplicationStatusNameType
  count: number
}

export type ApplicationStatusNameType =
  | '지원자'
  | '서류 합격'
  | '최종 합격'
  | '불합격'
  | '지원서 마감'
  | '불합격 예약'

export interface DashboardApplicationSummary {
  position: {
    id: number
    name: string
    careerType: WithIdName | null
    isPublished: boolean
  }
  appliedCount: number
  newAppliedCount: number
  approachingAutoRejectCount: number
  status: ApplicationStatus[]
}

export interface ApplicationCommon {
  id: number
  coverLetter: string | null
  joinReason: string | null
  fitReason: string | null
  fulfillments: ApplicationFulfillment
  position: { id: number; name: string; careerType: string }
  statusId: number
  appliedAt: string
  acceptedAt: string | null
  rejectedAt: string | null
  resumeAcceptedAt: string | null
}

export interface ApplicationDetail extends ApplicationCommon {
  startup: { id: number; name: string; thumbnail: string }
  rejectMessage: string | null
  canceledAt: string | null
  lastViewedAt: string | null
  firstViewedAt: string | null
}

export interface DashboardApplicationDetail extends ApplicationCommon {
  user: {
    name: string
    phone: string
    email: string
    image: string | null
  }
  dueDate: string
  isExtendedDueDate: boolean
  scheduledRejectionDate: string | null
  chatStep: DashboardChatStatusId
}

interface ApplicationItemCommon {
  unreadMessagesCount: number
  lastMessage: ChatMessage | null
}

export interface ApplicationItem
  extends ApplicationCommon,
    ApplicationItemCommon {
  startup: { id: number; name: string; thumbnail: string }
  files: string[]
  links: string[]
  rejectMessage: string | null
  canceledAt: string | null
  lastViewedAt: string | null
  firstViewedAt: string | null
}

export interface DashboardApplicationItem
  extends DashboardApplicationDetail,
    ApplicationItemCommon {
  scoutRequest?: DashboardScoutProfileItem // isFromChat 아닐 때(지원자 관리)
  scoutRequestId?: number // isFromChat 일 때(채팅)
}

export interface TempApplication extends WithIdName {
  coverLetter: string | null
  fitReason: string | null
  joinReason: string | null
  scoutRequestId: number
  appliedAt: string
  fulfillments: ApplicationFulfillment | null
  hasNewConditions: boolean
}

export interface ApplicationFulfillment {
  task: ApplicationFulfillmentCondition[]
  preferred: ApplicationFulfillmentCondition[]
  qualification: ApplicationFulfillmentCondition[]
}

export interface ApplicationFulfillmentCondition
  extends ApplicationFulfillmentParams {
  condition: string
}

export interface ApplicationsReqParams {
  applicationStatuses: number[]
  offset: number
  limit: number
  saveLists?: number[]
  isResumeAcceptExists?: boolean // 서류합격 후 불합격 된 데이터만 내려줌
  isFromChat?: boolean // scout request를 안내려줌
  chatStep?: DashboardChatStatusId
  userName?: string
  positionId?: number
}

export interface ScoutProposalDetailCommon extends WithIdName {
  fulfillments: ScoutFulfillment[]
  message: string
  isAutoRejected: boolean
  isRejected: boolean | null
  isCanceled: boolean
  isResumeRequest: boolean
  isResumeAllowed: boolean
  dueDate: string
  proposedAt: string
  status: ScoutProposalStatus
}

export interface DashboardScoutProposalDetail
  extends ScoutProposalDetailCommon {
  user: {
    name: string
    phone?: string // isAdmin일 경우만 내려줌
    email?: string // isAdmin일 경우만 내려줌
  }
  lastViewedAt: string | null
  chatStep: DashboardChatStatusId
  acceptedAt: string | null
  relatedPerStartupCurations?: AdminRelatedPerStartupCuration[] // 구직도우미 전용
}

export interface AdminRelatedPerStartupCuration {
  startup: WithIdName
  perStartupCuration: WithIdName
  perStartupCurationScoutRequestId: number
  // 추천 상태
  curatedAt: string // 추천일
  lastViewedAt: string | null // 프로필 조회
  proposedAt: string | null // 제안함
  rejectedAt: string | null // 불합격
  acceptedAt: string | null // 최종합격
  resumeAcceptedAt: string | null // 서류합격
  uninterestedAt: string | null // 관심 없음
  brokingRequestedAt: string | null // 연결 요청
  brokingRequestedResolvedAt: string | null // 연결 요청 처리 일시 (메시지를 보내지 않는 것이 처리 결과일 수도 있음)
  shouldBrokingScoutProposal: boolean // 직접 연결 필요 여부
  // 응답 상태
  brokingRequestStatus: BrokingRequestStatus
  brokingRequestStatusUpdatedAt: string | null
  brokingRequestStatusUpdatedBy: string | null // 처리한 구직도우미 계정의 username
}

export interface ScoutProposalDetail extends ScoutProposalDetailCommon {
  positions: PartialStartupPosition[]
}

export enum ChatStatus {
  NOT_RESPONSED = '미응답',
  NOT_ACCEPTED = '채팅 수락전',
  CHATTING = '채팅 진행중',
  MEET_CONFIRMED = '커피챗 예정',
  MEET_DONE = '커피챗 완료',
  ENDED = '채팅 종료',
  RESUME_ACCEPTED = '서류 합격',
  ACCEPTED = '최종 합격',
  REJECTED = '불합격',
}

export interface StartupUser {
  id: number // 이건 startup user 에 관한 id 임
  username: string
  startupId: number
  isPublished: boolean
  isSigned: boolean
  isLocked: boolean
  totalTempProposals: number
  isInfoFillRequest: boolean
  shouldAutoRemindProposalPendingTalent: boolean
  isPublishedStartupPosition: boolean
  pendingProposalCount: number
  newInterestedUserCount: number
  curationUpdate: {
    newCuratedScoutRequestCount: number
    newAcceptanceCount: number
    recentCurationId: number | null
    managerName: string | null // recentCurationId 가 있을 때만 값이 있을 수 있음
    managerImage: string | null // recentCurationId 가 있을 때만 값이 있을 수 있음
  } | null
  needChatStepCleanUp: boolean
  isAdmin: boolean
  agreements: HirePenaltyAgreements
}

interface HirePenaltyAgreements {
  agreedPenaltyForHireAfterCancellation: boolean //제안 취소 후 채용 미고지 불이익 동의
  agreedPenaltyForHireAfterRejection: boolean //불합격 후 채용 미고지 불이익 동의
  agreedPenaltyWhenIntentionalHiding: boolean //합격 후 채용 사실 의도적 미고지 불이익 동의)
  agreedHireNotification: boolean //채용 후 고지 동의
}

export type AlertItemType =
  | 'weeklyReport'
  | 'applicationApplied'
  | 'applicationAutoRejected'
  | 'applicationCanceled'
  | 'proposalAccepted'
  | 'proposalRejected'
  | 'newMessage'
  | 'unreadMessage'
  | 'meetScheduleRemind'
  | 'hireStatusUpdate'
  | 'positionUnpublished'
  | 'talentRecommendationForPosition'

export type NotificationChannelSettings = {
  isPhoneNoti: boolean
  isEmailNoti: boolean
}

export type AlertSettings = {
  [key in AlertItemType]: NotificationChannelSettings
}

export interface StartupPIC extends WithIdName {
  position: string
  phone: string | null
  email: string | null
  isStopAlertLateTime: boolean
  alertSettings: AlertSettings
}

export interface AlertSettingsPayload {
  alertType: AlertItemType
  isPhoneNoti?: boolean
  isEmailNoti?: boolean
}

export interface DashboardPosition {
  id: number
  name: string
  positionTypes: PositionType[]
  careerType: WithIdName | null
  experienceRange: Range | null
  techStacks: WithIdName[]
  hasSupervisor: boolean
  task: string
  qualification: string
  preferred: string
  hiringProcess: string
  expectationKeywords: WithIdName[]
  isPublished: boolean
  publishedAt: string | null
  applicationCount: number
  createdAt: string
  talentRecommendRequestedAt: string | null
}

export interface DashboardPositionParams {
  name: string
  positionTypes: number[]
  careerType: number | null
  experienceRange: Range | null
  techStacks: number[]
  hasSupervisor: boolean
  task: string
  qualification: string
  preferred: string
  hiringProcess: string
  expectationKeywords: number[]
}

export interface DashboardStartupDetail {
  id?: number
  name: string | null
  pitch: string | null
  pt: string | null
  thumbnail: string | File | null
  briefIntro: string | null
  tags: string[]
  funding: {
    round: number // id 투자 단계
    amount: number // 투자 유치액
    attractedAt: string // 투자 유치 날짜
  }[]
  reasonForInvesting: string | null // 투자받은 이유
  effortForDev: string | null // 개발자를 위한 노력
  solvingProblem: string | null // 해결하는 문제
  address: string | null
  location: NumberOrNull //id
  memberCount: NumberOrNull
  devCount: NumberOrNull
  compensation: string | null // 보상
  welfare: string | null // 복지
  images: (string | File)[]
  howToStartup: string | null // 어떻게 창업하셨나요?
  whatKindCompany: string | null // 어떤 회사인가요?
  curOutcome: string | null // 현재까지 성과
  vision: string | null // 미래, 비전이 궁금해요
  lookForDev: string | null // 어떤 분을 찾고 계신가요?
  culture: string | null // 조직문화
  members: StartupMember[]
  hasCto: boolean
  isPublished: boolean
  publishedAt: string
}

export interface AccountAlertSetting {
  shouldAlertGeneralService: boolean
  shouldAlertInterestedStartupUpdate: boolean
  shouldAlertNewFitStartup: boolean
  shouldAlertPromotion: boolean
  shouldAlertStartupsInterestedInMe: boolean
}

export interface WorkLevel extends WithIdName {
  description: string
}

export interface JobInterestLevel {
  id: number
  description: string
  workLevelId: number
}

export interface ExpectationKeyword extends WithIdName {
  categoryId: number
}

export interface RequestFormOptions {
  workLevels: WorkLevel[]
  educationCategories: WithIdName[]
  jobInterestLevels: JobInterestLevel[]
  locations: Location[]
  serviceAreas: ServiceArea[]
  fundingRounds: FundingRound[]
  educationStatus: WithIdName[]
  expectationCategories: WithIdName[]
  expectationKeywords: ExpectationKeyword[]
  languages: WithIdName[]
  languageLevels: WithIdName[]
  personalities: WithIdName[]
}

export interface Range {
  min: number
  max: number
}

export type ScoutRequestFormDataKey =
  | 'locations'
  | 'serviceAreas'
  | 'fundingRounds'
  | 'memberCount'
  | 'devCount'
  | 'salary'
  | 'shouldSupervisor'
  | 'shouldCto'
  | 'shouldStockOption'
  | 'remoteWorkPreference'

export type ScoutRequestDataKeyIsRequired = {
  dataKey: ScoutRequestFormDataKey
  isRequired: boolean
}

export type School = WithIdNameImage & {
  groups: number[] | null
}

export interface EducationHistory {
  id?: number
  status: WithIdName | null
  isCs: boolean
  major: string
  category: WithIdName | null
  admissionYear: string | null
  graduationYear: string | null
  school: School
  isCreatedByUser: boolean
  isCompleted?: boolean
  isEdited?: boolean
}

// Company 모델의 모든 필드를 포함한 타입
export interface Company extends WithIdName {
  image: string | null
  isStartup: boolean
  serviceAreas: WithIdName[]
  isCreatedByUser: boolean
  businessRegistrationNumber: string | null
}

// TODO: 기업 사이드에는 isCreatedByUser가 없음 -> 헷갈려서 실수할 수 있으니 타입 정리 or api 응답 통일 필요
export type CompanyForCareerHistory = Omit<
  Company,
  'businessRegistrationNumber' | 'serviceAreas'
>

export interface CareerHistory {
  id?: number
  company: CompanyForCareerHistory
  link: string | null
  position: string
  started: string
  ended: string | null
  isWorking: boolean
  techStacks: TechStack[]
  serviceAreas: WithIdName[]
  description: string | null
  isVerified: boolean
}

export interface LanguageSkill {
  id: number
  language: WithIdName
  level: WithIdName
  description: string
}

export interface OtherActivityHistory {
  id?: number
  title: string
  description: string
  started: string | null
  ended: string | null
  techStacks: TechStack[]
  isOnGoing: boolean
}

export interface AttachmentWithAccessibility extends WithIdLink {
  isAccessible: boolean
}

export interface DashboardScoutRequestDetail extends WithIdName {
  birthYear: string | null
  image: string | File
  summary: { title: string; content: string }[] | null
  workLevel: WithIdName
  jobInterestLevel: NumberOrNull
  isActivelySeeker: boolean
  memberCount: Range | null // 구직 조건의 경우 지원자 프로필에서 보여주지 않음
  devCount: Range | null
  salary: Range | null
  locations: Location[] | null
  serviceAreas: WithIdName[] | null
  fundingRounds: WithIdName[] | null
  miscOptions: ScoutRequestMiscOptions | null
  requiredConditions: ScoutRequestDataKeyIsRequired[] | null
  educations: EducationHistory[] // 스카우트 프로필 공개가 아니라 지원한 경우 값이 없을 수 있음
  languageSkills: LanguageSkill[]
  experience: number
  careers: CareerHistory[]
  otherActivities: OtherActivityHistory[]
  files: AttachmentWithAccessibility[]
  links: AttachmentWithAccessibility[]
  introduction: string | null
  personalities: WithIdName[]
  expectation: { description: string; keywords: WithIdName[] }
  jobSearchingReason: string | null
  positions: WithIdName[]
  techStacks: WithIdName[]
  idpCertificates: number[]
  updatedAt: string
  assessMemo: string | null
  saveLists: number[]
  lastActiveAt: string | null
  viewedAt: string | null
  viewedMeAt: string | null
  lastListedAt: string | null
  savedMeAt: string | null
  proposalId: NumberOrNull
  isProposed: boolean
  isCanceled: boolean
  isRejected: boolean | null
  isResumeAllowed: boolean
  startupDismissed: boolean
  status: ScoutProposalStatus | null
  hasPendingScoutProposal: boolean
  hasTempScoutProposal: boolean
  hasConfirmedMeet: boolean
  hasDoneMeet: boolean
  hasApplication: boolean
  respondedCount: number
  receivedMessageCount: number
  isCommonCurated: boolean
  managerComment: ManagerComment | null
  curatedAt: string | null
  isDeleted: boolean
  isPublished: boolean
  aiAssessment?: ScoutRequestAiAssessment | null
  filterAssessment?: ScoutRequestFilterAssessment | null
  assessmentScore?: number
  resultType?: 'filter' | 'boost'
}

export interface ScoutRequestMiscOptions {
  shouldCto: boolean
  shouldSupervisor: boolean
  shouldStockOption: boolean
  isSalaryNegotiableWithStockOption: boolean
  remoteWorkPreference: RemoteWorkType
}

export type RemoteWorkType =
  | 'PART_REMOTE_NECESSARY'
  | 'FULL_REMOTE_NECESSARY'
  | null

export interface ConditionForm {
  workLevel: number
  jobInterestLevel: NumberOrNull
  isActivelySeeker: boolean
  locations: number[]
  serviceAreas: number[]
  fundingRounds: number[]
  memberCount: Range
  devCount: Range
  salary: Range
  miscOptions: ScoutRequestMiscOptions
  requiredConditions: ScoutRequestDataKeyIsRequired[]
  lastSavedStep: number // 중도 이탈 시 나중에 돌아오면 다시 시작할 페이지. 저장하지 않으면 0
}

export interface BlockedCompany {
  startupId: number | null // 입점사인 경우만
  companyId: number | null // 미입점사인 경우만
  name: string
}

export interface ScoutProfile extends ConditionForm {
  id: number
  name: string
  image: string | File
  educations: EducationHistory[]
  languageSkills: LanguageSkill[]
  experience: number
  careers: CareerHistory[]
  otherActivities: OtherActivityHistory[]
  files: WithIdLink[]
  links: WithIdLink[]
  introduction: string
  personalities: WithIdName[]
  expectation: { description: string; keywords: WithIdName[] }
  jobSearchingReason: string | null
  isPublished: boolean
  hasRequestedInfoFill: boolean
  hasResolvedInfoFill: boolean
  hasRejectedInfoFill: boolean
  infoFillResolvedAt: string | null
  idpCertificates: IdpCertificateStatus[]
  idpScore: number
  idpGrade: IdpGrade
  exposureRankingRatio: number
  blockedCompanies: BlockedCompany[]
  updatedByUserAt: string
  publishedAt: string
  createdAt: string
}

export interface ScoutProfileDrafts {
  expectations: string[]
  introductions: string[]
  jobSearchingReasons: string[]
  careers: CareerHistory[]
  educations: EducationHistory[]
  languageSkills: LanguageSkill[]
  otherActivities: OtherActivityHistory[]
}
// career 저장/수정하는 PUT 요청할 때 body 파라미터
export interface ScoutProfileCareerPayload {
  id?: number
  name: string
  company: number | null
  serviceAreas: number[]
  isStartup: boolean
  link: string | null
  position: string
  started: string
  ended: string
  isWorking: boolean
  techStacks: number[]
  description: string
  isVerified: boolean
}

export interface OtherActivityHistoryPayload
  extends Omit<OtherActivityHistory, 'techStacks'> {
  techStacks: number[]
}

export interface LanguageSkillPayload {
  language: number
  level: number
  description: string
}

export interface EducationPayload
  extends Omit<
    EditableEducation,
    'status' | 'category' | 'image' | 'isCreatedByUser'
  > {
  category: number | null
  status: number
}

export interface ScoutRequestParams extends ConditionForm {
  addedEducations: EducationPayload[]
  removedEducations: number[]
  addedLanguageSkills: LanguageSkillPayload[]
  removedLanguageSkills: number[]
  addedCareers: ScoutProfileCareerPayload[]
  removedCareers: number[]
  addedOtherActivities: OtherActivityHistoryPayload[]
  removedOtherActivities: number[]
  files: number[]
  addedLinks: string[]
  removedLinks: number[]
  introduction: string
  personalities: number[]
  expectation: { description: string; keywords: number[] }
  jobSearchingReason: string
}

export interface VerifiedCareer {
  name: string
  started: string
  ended: string
  companyId: number | null
}

export type IdpGrade = 1 | 2 | 3 | 4 | 5

export interface IdpCertificateStatus {
  id: number
  isCertified: boolean | null
  isContentFilled: boolean
}

export interface Condition {
  dataKey: ScoutRequestFormDataKey
  value: string
}

export interface ScoutPosition extends WithIdName {
  careerType: string
  techStacks: string[]
}

export enum WebSocketMessageType {
  WRITE = 'chat.message.write',
  READ = 'chat.message.read',
  LIST = 'chat.message.list',
}

export interface ApplicationsParams {
  applicationStatuses: number[]
  isResumeAcceptExists?: boolean
}

export enum ChatMessageType {
  INITIAL = 'initial',
  NORMAL = 'normal',
  FILE = 'file',
  CANCEL = 'cancel',
  REJECT = 'reject',
  ACCEPT = 'accept',
  RESUME_REQUEST = 'resumeRequest',
  RESUME_ALLOWED = 'resumeAllowed',
  MEET_DESIRE = 'meetDesire',
  MEET_PROPOSE = 'meetPropose',
  MEET_CONFIRM = 'meetConfirm',
  APPLICATION_RESUME_ACCEPT = 'applicationResumeAccept',
  APPLICATION_APPLY = 'applicationApply',
  APPLICATION_REJECT = 'applicationReject',
  APPLICATION_ACCEPT = 'applicationAccept',
  SCOUT_REQUEST_INFO_FILL_REQUEST = 'scoutRequestInfoFillRequest',
  BROKING_SCOUT_PROPOSAL = 'brokingScoutProposal',
  BROKING_SCOUT_PROPOSAL_ACCEPT = 'brokingScoutProposalAccept',
  BROKING_SCOUT_PROPOSAL_REJECT = 'brokingScoutProposalReject',
}

export interface BaseChatMessage {
  type: WebSocketMessageType.WRITE
  proposalId: NumberOrNull
  applicationId: NumberOrNull
  messageId: number
  startupId: NumberOrNull
  userId: NumberOrNull
  sentAt: string
  readAt: string | null
}

export interface ApplicationAcceptChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.APPLICATION_ACCEPT
}

export interface ApplicationRejectChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.APPLICATION_REJECT
  message: string
}

export interface InitialChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.INITIAL
}

export interface ApplicationApplyChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.APPLICATION_APPLY
  message: string
}

export interface ApplicationResumeAcceptChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.APPLICATION_RESUME_ACCEPT
  message: string
}

export interface NormalChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.NORMAL
  message: string
}

export interface FileChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.FILE
  file: AttachmentWithAccessibility
}

export interface CancelChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.CANCEL
  message: string
}

export interface RejectChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.REJECT
  message: string
}

export interface AcceptChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.ACCEPT
}

export interface ResumeRequestChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.RESUME_REQUEST
  isResumeAllowed: boolean
}

export interface ResumeAllowedChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.RESUME_ALLOWED
}

export interface ScoutRequestInfoFillRequestChatMessage
  extends BaseChatMessage {
  messageType: ChatMessageType.SCOUT_REQUEST_INFO_FILL_REQUEST
  message: string
}

export interface BrokingScoutProposalChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.BROKING_SCOUT_PROPOSAL
  message: string
  perStartupCurationScoutRequestId: number
}

export interface BrokingScoutProposalAcceptChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.BROKING_SCOUT_PROPOSAL_ACCEPT
  message: string
  startupName: string
}

export interface BrokingScoutProposalRejectChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.BROKING_SCOUT_PROPOSAL_REJECT
  message: string
  startupName: string
}

export interface MeetDesire {
  meetDesireId: number
  isReplied: boolean
  preferDates: string[]
  preferOffline: boolean
  preferOnline: boolean
  isAlreadyConfirmed: boolean
}

export interface MeetConfirm {
  date: string
  isOffline: boolean
  isOnline: boolean
  offlineAddress: string | null
  onlineLink: string | null
}

export interface MeetDesireChatMessage extends BaseChatMessage {
  messageType: ChatMessageType.MEET_DESIRE
  meetDesire: MeetDesire
}

export interface MeetProposeMessage extends BaseChatMessage {
  messageType: ChatMessageType.MEET_PROPOSE
  meetPropose: {
    id: number
    officeAddress: string
    isReplied: boolean
  }
}

export interface MeetConfirmMessage extends BaseChatMessage {
  messageType: ChatMessageType.MEET_CONFIRM
  meetConfirm: MeetConfirm
}

export type ChatMessage =
  | InitialChatMessage
  | NormalChatMessage
  | FileChatMessage
  | CancelChatMessage
  | RejectChatMessage
  | AcceptChatMessage
  | ResumeRequestChatMessage
  | ResumeAllowedChatMessage
  | MeetDesireChatMessage
  | MeetProposeMessage
  | MeetConfirmMessage
  | ApplicationResumeAcceptChatMessage
  | ApplicationApplyChatMessage
  | ApplicationRejectChatMessage
  | ApplicationAcceptChatMessage
  | ScoutRequestInfoFillRequestChatMessage
  | BrokingScoutProposalChatMessage
  | BrokingScoutProposalAcceptChatMessage
  | BrokingScoutProposalRejectChatMessage

export interface Chat {
  lastMessage: ChatMessage | null
  unreadMessagesCount: number
  isBlocked?: boolean
}

export interface ChatData {
  type: WebSocketMessageType
  proposalId: NumberOrNull
  applicationId: NumberOrNull
}

export interface MessageList extends ChatData {
  messages: ChatMessage[]
}

export interface ReadMessage extends ChatData {
  messageId: number
}

export interface ScoutProposalCommon {
  name: string
  requestId: number
  proposalId: number
  dueDate: string
  isCanceled: boolean
  isRejected: boolean
  hasConfirmedMeet: boolean
  hasDoneMeet: boolean
  lastMessage: ChatMessage
  status: ScoutProposalStatus
  unreadMessagesCount: number
  proposedAt: string
  acceptedAt: string | null
}

export interface ScoutProposal extends ScoutProposalCommon {
  startupId: number
  thumbnail: string
}

export interface DashboardScoutProposal extends ScoutProposalCommon {
  profileImage: string | null
  chatStep: DashboardChatStatusId
  shouldBrokingScoutProposal?: boolean // 구직도우미 전용
}

export interface ScoutFulfillment {
  dataKey: ScoutRequestFormDataKey
  description: null | string
}

export interface DashboardTempScoutProposal {
  appealPoints: string
  fulfillments: ScoutFulfillment[]
  introPerson: string
  outro: string
  positions: number[]
  proposeReason: string
  isResumeRequest: boolean
}

export interface DashboardInitialScoutProposal {
  fulfillments: ScoutFulfillment[]
  introPerson: string
  outro: string
  proposeReason?: string
  appealPoints?: string
}

export interface DesireMeetParams {
  preferDates: string[]
  preferOnline: boolean
  preferOffline: boolean
  meetProposeId: NumberOrNull
}

export interface DashboardSimpleScoutProfile extends WithIdName {
  birthYear: string | null
  image: string
  isActivelySeeker: boolean
  jobInterestLevel: NumberOrNull
  workLevel: WithIdName
  techStacks: WithIdName[]
  positions: WithIdName[]
  careers: CareerHistory[]
  otherActivities: OtherActivityHistory[]
  experience: number
  educations: EducationHistory[]
  introduction: string | null
  idpCertificates: number[]
  viewedAt: string | null // 기업이 인재 확인
  receivedMessageCount: number
  respondedCount: number
  assessMemo?: string
  summary: { title: string; content: string }[] | null
  isCommonCurated: boolean
  managerComment: ManagerComment | null
}

export interface ManagerComment {
  manager: {
    id: number
    name: string
    image: string
  } | null
  comment: string
}

export interface DashboardScoutProfileItem extends DashboardSimpleScoutProfile {
  hasTempScoutProposal: boolean
  hasApplication: boolean
  saveLists: number[]
  isProposed: boolean
  hasConfirmedMeet: boolean
  hasDoneMeet: boolean
  isCanceled: boolean
  isRejected: boolean
  startupDismissed: boolean
  proposalId?: number
  updatedAt: string
  lastActiveAt: string | null
  lastListedAt: string | null
  viewedMeAt: string | null // 인재가 기업 확인
  savedMeAt: string | null // 인재가 기업 저장
  hasPendingScoutProposal: boolean
  isNew?: boolean
  requiredConditions: ScoutRequestDataKeyIsRequired[] | null
  locations: Location[] | null
  serviceAreas: WithIdName[] | null
  fundingRounds: WithIdName[] | null
  memberCount: Range | null
  devCount: Range | null
  salary: Range | null
  miscOptions: ScoutRequestMiscOptions | null
  aiAssessment?: ScoutRequestAiAssessment | null
  filterAssessment?: ScoutRequestFilterAssessment | null
  assessmentScore?: number
  resultType?: 'filter' | 'boost'
}

export type DashboardCuratedScoutRequest = DashboardScoutProfileItem &
  CurationStatus

export interface DashboardCuratedScoutsRequests {
  newCurated: DashboardCuratedScoutRequest[]
  waiting: DashboardCuratedScoutRequest[]
  accepted: DashboardCuratedScoutRequest[]
  rejected: DashboardCuratedScoutRequest[]
  breakdown: DashboardCuratedScoutRequest[]
  uninterested: DashboardCuratedScoutRequest[]
}

export type BrokingRequestStatus = 'ACCEPTED' | 'REJECTED' | null

// 지원 또는 제안 이력이 있는 프로필은 걸러주기 때문에 응답값이 조금 다름
export interface DashboardInterestedInMeProfileItem
  extends DashboardSimpleScoutProfile {
  hasPendingScoutProposal: boolean
  hasTempScoutProposal: boolean
  saveLists: number[]
  proposalId?: number
  updatedAt: string
  lastActiveAt: string | null
  lastListedAt: string | null
  viewedMeAt: string | null // 인재가 기업 확인
  savedMeAt: string | null // 인재가 기업 저장
  isNew: boolean
  requiredConditions: ScoutRequestDataKeyIsRequired[] | null
  locations: Location[]
  serviceAreas: WithIdName[]
  fundingRounds: WithIdName[]
  memberCount: Range | null
  devCount: Range | null
  salary: Range | null
  miscOptions: ScoutRequestMiscOptions | null
}

export interface DashboardScoutsSummary {
  proposalUnreadMessagesCount: number
  applicationUnreadMessagesCount: number
  unreadMessagesCount: number
  proposalCount: number
  assessmentCount: number
}

export type Progress = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

export interface WithYearMonth {
  year: string
  month: string
}

export interface EditableEducation extends Omit<EducationHistory, 'school'> {
  name: string | null
  image: string | null
  school: number | null
  backup?: EditableEducation
}

export interface EditableCareer {
  id?: number
  name: string
  company: number | null
  isStartup: boolean
  serviceAreas: WithIdName[]
  link: string | null
  image: string | null
  position: string
  started: WithYearMonth
  ended: WithYearMonth
  isWorking: boolean
  techStacks: TechStack[]
  description: string
  isVerified: boolean
  isCompleted: boolean // '완료' 하는 시점에 true로 바뀌고, 삭제되지 않는 한 다시 false가 되지 않음
  isCreatedByUser: boolean
  isEdited?: boolean
  backup?: EditableCareer
}

export interface EditableOtherActivity {
  id?: number
  title: string
  description: string
  started: WithYearMonth
  ended: WithYearMonth
  isOnGoing: boolean
  isCompleted: boolean // '완료' 하는 시점에 true로 바뀌고, 삭제되지 않는 한 다시 false가 되지 않음
  techStacks: TechStack[]
  isEdited?: boolean
  backup?: EditableOtherActivity
}

export interface EditableLanguageSkill {
  id?: number
  language: WithIdName | null
  level: WithIdName | null
  description: string
  isCompleted: boolean
  isEdited: boolean // 저장된 적 있는(id 부여됨) 데이터를 수정해서, 다시 저장할 필요가 있는지 (요청서 작성 플로우에서만 사용)
  backup?: EditableLanguageSkill
}

export type ProfileFormPages =
  | 'education'
  | 'introduce'
  | 'expectation'
  | 'career'
  | 'resume'
  | 'language'
  | 'image'
  | 'done'
  | 'job-searching-reason'

export type AutoAnonymizationResult =
  | 'FILE_CREATED'
  | 'FILE_CREATION_FAILED'
  | 'NOT_FOR_ANONYMIZATION'
  | 'UNSUPPORTED_FILE_FORMAT'

export type FileSystemVersion = 1 | 2 // 1은 프로필 첨부자료, 2는 인재 배지 소개자료

export interface AdminFile {
  applicationFileId: NumberOrNull // 프로필 첨부자료
  userFileId: NumberOrNull // 인재 배지 소개자료
  name: string
  link: string
  createdAt: string
  anonymizedKey: string | null
  anonymizedFileCreatedAt: string | null
  anonymizedBy: string | null
  isNotForAnonymization: boolean
  autoAnonymizationResult: AutoAnonymizationResult | null
  autoAnonymizedAt: string | null
  isReviewed: boolean | null
  reviewedBy: string | null
  fileSystemVersion: FileSystemVersion
}

export interface PaginationParams {
  offset: number
  limit: number
}

export interface AdminFilesReqParams extends PaginationParams {
  showApplicationFiles: boolean
  showUserFiles: boolean
  isReviewed?: boolean
}

export interface IdpCertificate extends WithIdName {
  description: string
  requirement: string
  imageUrl: string
  detailQuestions: WithIdName[]
  traits: CertificateTrait[]
  type: 'file&link' | 'link' | 'text'
  textPlaceholder: string
}

export type CertificateTrait =
  | '자기주도성'
  | '커뮤니케이션'
  | '오너십'
  | '책임감'
  | '실무 능력'
  | '스타트업 이해도'
  | '문제해결력'

export interface UserIdpCertificateDetail {
  id: number
  certText: string | null
  isCertified: boolean
  isContentFilled: boolean
  idpCertificateId: number
  details: {
    questionId: number
    answer: idpCertificateAnswer
  }[]
  certFiles: WithIdLink[]
  certLinks: WithIdLink[]
  introFiles: WithIdLink[]
  introLinks: WithIdLink[]
}

export interface idpCertificateAnswer {
  id: number
  content: string
}

export type CertificateFileType = File | WithIdLink | null

interface IntroQnADetail {
  questionId: number
  answer: string
}

export interface DashboardIdpCertificateDetail {
  idpCertificateId: number
  details: IntroQnADetail[]
  introFiles: AttachmentWithAccessibility[]
  introLinks: AttachmentWithAccessibility[]
}

export interface AdminUserIdpCertificate extends WithIdName {
  certText: string
  isCertified: boolean
  certifiedAt: string
  certifiedBy: string
  createdAt: string
  rejectReason: string
  evaluatedAt: string
  evaluatedBy: string
  phone: string
  idpCertificateId: number
  certFiles: string[]
  certLinks: string[]
  scoutRequestId: number
}

export interface AdminStartup extends WithIdName {
  username: string
  mainEmailPIC: { name: string; position: string; email: string } | null
  remarks: string
  isLocked: boolean
  isSigned: boolean
  isFilledUp: boolean
  isEssential: boolean
  isPublished: boolean
  createdAt: string
}

export interface AdminStartupFunnel {
  overview: {
    // 퍼센티지 *100 없이 다 소숫점, 프론트에서 포맷팅(1의자리 반올림)
    target: number // 목표
    current: number // 현재
    onboarding: number // 입점
    scout: number // 스카우트 채용
    application: number // 지원자 채용
  }
  onboarding: AdminStartupBoard[]
  scout: AdminStartupBoard[]
  application: AdminStartupBoard[]
}

export interface AdminStartupBoard {
  name: string // 정식 계정 발급됨
  prevConversion: number // 이전
  aggConversion: number // 누적
  items: AdminStartupItem[]
}

export interface AdminStartupItem extends WithIdName {
  image: string
  joinedAt: string
  currentStatusFrom: string
  lastLoginAt: string
  lastActiveAt: string
  scout: {
    unresponded: number // 미응답채팅
    assessed: number // 1~4점 평가
    interested: number // 3~4점 평가
    proposed: number
    effective: number // 아직 거절/수락되지 않은 유효 제안 개수
    devReplied: number // 회신 받아 채팅한 개수
    confirmedMeets: number
    hired: number
    lastAssessedAt?: string
    lastProposedAt?: string
    lastMsgSentAt: string
  }
  application: {
    unresponded: number // 미응답지원
    positions: number
    applications: number
    resumeAccepted: number
    accepted: number
    hired: number
    lastViewedAt?: string // 지원자 마지막 열람
    lastDecidedAt?: string // 마지막 합불 액션
  }
}

export type ProfileSearchOrderBy =
  | '-idpScore'
  | '-updatedByUserAt'
  | '-relevance'

interface DashboardScoutRequestSearchCommonParams {
  minExperience: number
  maxExperience: number
  minSalary: number
  maxSalary: number
  isEducationCs: boolean
  isEducationFinished: boolean
  isJobInterested: boolean
  hasIntroduction: boolean
  hasAccessibleAttachment: boolean
  keywords: string[]
  jobInterestLevels: number[]
  isNotWorking: boolean
  orderBy: ProfileSearchOrderBy
  isExcludeViewedTalent: boolean
  isRemoteWorkExcluded: boolean
  hasStartupCareerHistory: boolean
}

export interface DashboardScoutRequestSearchFilterParams
  extends DashboardScoutRequestSearchCommonParams {
  positions: PositionType[]
  techStacks: WithIdName[]
  workLevels: WithIdName[]
  idpCertificates: IdpCertificate[]
  schoolGroups: SchoolGroup[]
  schools: WithIdName[]
  educationCategories: WithIdName[]
  languageSkills: {
    id?: number
    language: WithIdName
    level: WithIdName | null
  }[]
  locations: WithIdName[]
  fundingRounds: WithIdName[]
  serviceAreas: WithIdName[]
  careerServiceAreas: WithIdName[]
  aiAssessmentConditions: string[]
  updatedAt?: string
}

export interface DashboardScoutSearchItem
  extends WithIdName,
    DashboardScoutRequestSearchFilterParams {}

export interface DashboardScoutRequestOneQueryParams
  extends DashboardScoutRequestSearchCommonParams {
  positions: number[]
  techStacks: number[]
  workLevels: number[]
  idpCertificates: number[]
  schoolGroups: number[]
  schools: number[]
  educationCategories: number[]
  languageSkills: string
  locations: number[]
  fundingRounds: number[]
  serviceAreas: number[]
  careerServiceAreas: number[]
  aiAssessmentConditions: string[]
  searchId?: number
}

export interface DashboardScoutRequestListQueryParams
  extends DashboardScoutRequestOneQueryParams {
  limit: number
  offset: number
}

export interface DashboardScoutSaveProfileListParams {
  offset: number
  limit: number
  isNotProposed: boolean
}

export interface ProfileSaveListItem extends WithIdName {
  count: number
  chatCount: number
}

export interface UserTokenPayload {
  user_id: number
  username: string
  is_fake: boolean
  exp: number
  // TODO(gogo): created_at 지우고 iat 만 유지
  iat?: number // 아직은 ? 처리
  created_at?: string // deprecated => iat 로 대체
}

export interface StartupUserTokenPayload {
  startup_user_id: number
  startup_id: number
  startup_name: string
  is_fake: boolean
  is_admin: boolean
  exp: number
  // TODO(gogo): ditto
  iat?: number
  created_at?: string // deprecated => iat 로 대체
}

export interface StartupInterestedInMe extends StartupForSearch {
  startupPosition:
    | (PartialStartupPosition & { userApplication: UserApplicationForPosition })
    | null
}

export interface DashboardInterestedInMeParams extends PaginationParams {
  isSavedMe: boolean
}

export interface CuratedScoutProfile {
  scoutRequestId: number
  userId: number
  name: string
  experience: number
  workLevel: number
  positions: string[]
  curatedAt: string
  perStartupCurationComment: {
    comment: string | null
    commentedBy: (WithIdName & { image: string }) | null
  } | null
  commonComment: {
    comment: string | null
    commentedBy: (WithIdName & { image: string }) | null
  } | null
  isCommonCurated: boolean
  resumeAcceptedAt: string | null
  acceptedAt: string | null
  rejectedAt: string | null
  proposedAt: string | null
  lastViewedAt: string | null
  uninterestedAt: string | null
  uninterestedFeedbackAt: string | null
  uninterestedFeedback: string | null
  brokingRequestedAt: string | null
  memo: string | null
  jobHelperAssessMemo: string | null
  isDeleted: boolean
  deleteReason: string | null
  deletedAt: string | null
  isPublished: boolean
  unPublishedAt: string | null
}

export interface DashboardScoutsCuration extends WithIdName {
  count: number
  isHide: boolean
  newCuratedScoutRequestCount: number
  hasNewAcceptance: boolean
  managerName: string
  managerImage: string
}

export interface UserSearchingReqParams {
  offset?: number
  limit?: number
  searchQuery?: string
  role?: 'admin'
}

export interface ChatItem {
  name: string
  image: string | null
  proposalId: NumberOrNull
  applicationId: NumberOrNull
  lastMessage: ChatMessage | null
  unreadMessagesCount: number
  chatStep?: DashboardChatStatusId
  isRejected?: boolean
  requestId?: number
  startupId?: number
  position?: { name: string; careerType: string }
  status?: ScoutProposalStatus
  statusId?: number
  isCanceled?: boolean
  hasDoneMeet?: boolean
  hasConfirmedMeet?: boolean
  rejectedAt?: string | null
  acceptedAt?: string | null // proposal 일 때 인재가 채팅 수락한 시점
  resumeAcceptedAt?: string | null
  scheduledRejectionDate?: string | null
  shouldBrokingScoutProposal?: boolean // 구직도우미 전용
}

export interface SearchedUser extends WithIdNameImage {
  scoutRequestId: number
  workLevel?: number
  experience?: number
  interestedPositionTypes?: WithIdName[]
}

export interface BaseTrackingInfo {
  id: number
  startup: WithIdName
  user: WithIdName & { phone: string; scoutRequestId: number }
  manager: WithIdNameImage | null
  hireCandidateStatusId: HireCandidateStatus
  lastContact: string // TODO: 제거 예정
  memo: string // TODO: 제거 예정
  hireTrackingMemo: string | null
  deleteReason: string | null
  deletedAt: string | null
  isPublished: boolean
}

export interface AdminTrackingScoutProposal extends BaseTrackingInfo {
  proposedAt: string
  scoutProposalStatusId: ScoutProposalStatus
  acceptedAt: string
  rejectedAt: string
  canceledAt: string
  lastMessagedAt: string
  chatMemo: string
  isCheckNeeded: boolean
  chatMessagesCheckedAt: string
}

export interface AdminTrackingManualMatch extends BaseTrackingInfo {
  createdAt: string
}

export interface AdminTrackingHire extends BaseTrackingInfo {
  hiredAt: string
  hasContractConfirmed: boolean
  manualMatchId?: number
  scoutProposalId?: number
  positionApplicationId?: number
}

export interface AdminTrackingPositionApplication extends BaseTrackingInfo {
  appliedAt: string
  lastViewedAt: string
  applicationStatusId: number
  resumeAcceptedAt: string | null
  rejectedAt: string | null
  acceptedAt: string | null
  chatMemo: string
  chatMessagesCheckedAt: string
}

export type HireCandidateStatus =
  | 'TRACKING'
  | 'POSSIBLE'
  | 'IMPOSSIBLE'
  | 'HIRED'

export type ScoutProposalStatus =
  | 'WAITING'
  | 'ACCEPTED'
  | 'CANCELED'
  | 'REJECTED'
  | 'SUCCEEDED'

export enum HireCandidateStatusMapping {
  TRACKING = '⚪️ 추적중',
  POSSIBLE = '🟢 가능성',
  HIRED = '🔵 채용 확정',
  IMPOSSIBLE = '⚫️ 결렬',
}

export enum ScoutProposalStatusMapping {
  WAITING = '대기',
  ACCEPTED = '수락',
  CANCELED = '취소',
  REJECTED = '거절',
  SUCCEEDED = '합격',
}

export interface BaseTableColumn {
  id: string
  label: string
  minWidth?: number
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit'
}

export interface AdminHireTrackingStatus {
  hireCandidateStatus: HireCandidateStatus[]
  applicationStatus: WithIdName[]
  scoutProposalStatus: ScoutProposalStatus[]
  perStartupCurationStatus: PerStartupCurationStatus[]
}

export interface BaseTrackingTableParams {
  userIds: number[]
  startupIds: number[]
  managerIds: number[]
  hireCandidateStatusIds: string[]
}

export type AdminTrackingManualMatchParams = BaseTrackingTableParams

export type ScoutProposalOrderBy = '-lastMessagedAt' | '-proposedAt'

export type ApplicationOrderBy = '-resumeAcceptedAt' | '-appliedAt'

export interface AdminTrackingScoutProposalParams
  extends BaseTrackingTableParams {
  orderBy: ScoutProposalOrderBy
  scoutProposalStatusIds: string[]
}

export interface AdminTrackingApplicationParams
  extends BaseTrackingTableParams {
  orderBy: ApplicationOrderBy
  applicationStatusIds: number[]
}

export interface AdminHireCandidatePutRequestParams {
  manualMatchId?: number
  scoutProposalId?: number
  positionApplicationId?: number
  isCheckNeeded?: boolean
  chatMemo?: string
  managerId?: NumberOrNull
  hireCandidateStatusId?: string
}

export interface AdminChatMessage {
  content: string | null
  messageType: ChatMessageType
  sentAt: string
  sentBy: string
}

export interface TrackingHireBetaFilterParams {
  isLocked?: boolean
  hasInProgress?: boolean
  alertLevels?: TrackingHireAlertLevel
  isHide?: boolean
  isSelfReported?: boolean
  overWeeks?: number
}

export type TrackingStatusFilterName =
  | 'Level 1'
  | 'Level 2'
  | '운영팀 직접 연락'
  | '숨김 처리한 기업'

export type TrackingHireAlertLevel = 1 | 2 | 3

export interface TrackingStatusFilterOption {
  alertLevel: TrackingHireAlertLevel | null
  isHide: boolean
}

export interface TrackingHireStatusInfo {
  filterOption: TrackingStatusFilterOption
  count: number
}

export type TrackingHireAlertReply = 'confirm' | 'inProgress' | 'breakdown'

export enum TrackingHireAlertReplyMapping {
  confirm = '채용자 O',
  inProgress = '진행중',
  breakdown = '모두 결렬',
}

// 어드민 채용추적 페이지에서 쓰이는 채팅 상태
export enum AdminMatchingListChatStatus {
  INPROGRESS = 'INPROGRESS', // DashboardChatStatusType.INPROGRESS 와 달리, 수락전(WAITING) 상태는 포함하지 않음
  CONTACTLATER = 'CONTACTLATER',
  BREAKDOWN = 'BREAKDOWN',
  SUCCEEDED = 'SUCCEEDED',
  WAITING = 'WAITING', // DashboardChatStatusType에는 없는 상태
}

export enum AdminMatchingListChatStatusMapping {
  INPROGRESS = '진행중',
  CONTACTLATER = '추후 연락',
  BREAKDOWN = '결렬',
  SUCCEEDED = '최종 합격',
  WAITING = '수락전',
}

export interface MatchingListUserItem {
  proposalId: NumberOrNull
  applicationId: NumberOrNull
  chatStepUpdatedAt: string | null
  lastMessageSentAt: string
  userName: string
  hireCandidateStatusId: HireCandidateStatus
  chatMemo: string | null
  deletedAt: string | null
  isPublished: boolean
}

export interface MatchingListPerChatStep {
  chatStep: AdminMatchingListChatStatus
  count: number
  userList: MatchingListUserItem[]
}

export interface TrackingHireStartup {
  startupId: number
  startupName: string
  isSigned: boolean
  memo: string | null
  lastActiveAt: string | null
  isSelfReported: boolean
  lockElapsedDay: NumberOrNull
  isHide: boolean
  alertStatus: {
    notifiedAt: string
    alertLevel: TrackingHireAlertLevel
    alertReply: TrackingHireAlertReply | null
  } | null
  answerSucceededStep: boolean | null
  matchingList: MatchingListPerChatStep[]
}

export interface TrackingHireStartupSimple {
  name: string
  isSigned: boolean
  alertLevel: TrackingHireAlertLevel | null
  alertReply: TrackingHireAlertReply | null
  isHide: boolean
  hasInProgress: boolean
  lockElapsedDay: NumberOrNull
  lastActiveAt: string | null
  memo: string | null
  chatStep: { id: AdminMatchingListChatStatus; count: number }[]
  hireCandidateStatus: { id: HireCandidateStatus; count: number }[]
}

export interface TrackingHireUserListFilterParams {
  chatStep: AdminMatchingListChatStatus[]
  hireCandidateStatus: HireCandidateStatus[]
  orderBy?: 'lastMessageSentAt' // 파라미터 안 보내면 기본값은 내림차순(최신순)
}

export interface MatchingListStartupItem {
  proposalId: NumberOrNull
  applicationId: NumberOrNull
  startupId: number
  startupName: string
  chatStep: AdminMatchingListChatStatus
  chatStepUpdatedAt: string | null
  hireCandidateStatusId: HireCandidateStatus
  chatMemo: string | null
}

export interface TrackingHireMatchedUser {
  userId: number
  userName: string
  scoutRequestId: number
  proposalId: number | null
  applicationId: number | null
  phone: string
  memo: string | null
  chatStep: AdminMatchingListChatStatus
  chatStepUpdatedAt: string | null
  lastMessageSentAt: string | null // 예전 서류합격 건은 기획 변경 후 채팅방 만들어졌으나 메시지 없을 수 있음
  hireCandidateStatusId: HireCandidateStatus
  chatMemo: string | null
  deletedAt: string | null
  matchingList: MatchingListStartupItem[]
  isPublished?: boolean
}

export interface CurationPosition extends WithIdName {
  position: WithIdName
  startup: WithIdName
  manager: WithIdNameImage | null
  isLowPriority: boolean
  statuses: PerStartupCurationStatus[]
  lastViewedAt?: string | null
  scoutRequestCount?: number
  viewedScoutRequestCount?: number
}

export interface CurationPositionDetail extends CurationPosition {
  startup: WithIdName & { remarks: string }
  lastViewedAt: string
  requirement: string
  memo: string
}

export interface Comment {
  comment: string | null
  commentedBy: WithIdNameImage | null
}

export enum PerStartupCurationStatusMapping {
  NEED_CURATION = '추천 필요',
  NEED_FEEDBACK = '피드백 대기',
  NEED_MATCHING = '연결 필요',
  MATCHED = '연결 완료',
  DROPPED = '취소/포기',
}

export type PerStartupCurationStatus =
  | 'NEED_CURATION'
  | 'NEED_FEEDBACK'
  | 'NEED_MATCHING'
  | 'MATCHED'
  | 'DROPPED'

export interface ScoutPreAnalysis {
  similarTalentProposedCount: number
  startupsSeekingMeCount: number
}

export interface CurationStatus {
  curatedAt: string | null
  brokingRequestedAt: string | null
  uninterestedAt: string | null
  uninterestFeedback: string | null
  uninterestFeedbackAt: string | null
  brokingRequestStatus: BrokingRequestStatus
  proposedAt: string | null
  rejectedAt: string | null
  acceptedAt: string | null
  brokingRequestStatusUpdatedAt: string | null
  brokenAt: string | null
  canceledAt: string | null
  userAcceptedAt: string | null
}

export type NumberOrNull = number | null

export interface WithIdNameImage extends WithIdName {
  image: string | null
}

export interface SelectedChat {
  proposalId: NumberOrNull
  applicationId: NumberOrNull
}

export interface SucceededChat extends SelectedChat {
  name: string
  image: string | null
  workStartDate: string
}

export interface DashboardChatStatusPayload {
  proposalId: NumberOrNull
  applicationId: NumberOrNull
  chatStep: DashboardChatStatusId
}

export interface ApplicationsAcceptPayload {
  applicationId: number
  workStartDate: string | null
}

export interface ProposalsHirePayload {
  proposalId: number
  workStartDate: string | null
}
export interface UserAccount {
  username: string
  name: string
  phone: string
  email: string
  isAdmin: boolean
  experience: string
  positions: PositionType[]
  techStacks: TechStack[]
  shouldGenerateScoutRequest: boolean
}

export enum DashboardChatStatusType {
  INPROGRESS = 'INPROGRESS',
  CONTACTLATER = 'CONTACTLATER',
  BREAKDOWN = 'BREAKDOWN',
  SUCCEEDED = 'SUCCEEDED',
}

// TODO: 타입 리팩토링
export type DashboardChatStatusId =
  | DashboardChatStatusType.INPROGRESS
  | DashboardChatStatusType.CONTACTLATER
  | DashboardChatStatusType.BREAKDOWN
  | DashboardChatStatusType.SUCCEEDED

export enum DashboardChatStatusIdMapping {
  INPROGRESS = '진행중',
  CONTACTLATER = '추후 연락',
  BREAKDOWN = '결렬',
  SUCCEEDED = '최종 합격',
}

export interface DashboardChatStatus {
  id: DashboardChatStatusId
  count: number
  hasUnreadMessage: boolean
}

export type HidePenaltyNoticeType =
  | 'application'
  | 'proposal'
  | 'chatStatusClearStep'

export interface DashboardScoutsTextToSearch {
  id: number
  text: string
  explain: string
  inputSearch: DashboardScoutRequestSearchFilterParams
  outputSearch: DashboardScoutRequestSearchFilterParams
  createdAt: string
  updatedAt: string
}

export interface DashboardScoutsSearchOptions {
  schoolGroups: SchoolGroup[]
}

export interface DashboardScoutsSearchInfo {
  id: number
  name: string
  updatedAt: string
}

export interface ProposalReqParams {
  limit?: number
  offset?: number
  userName?: string
  saveLists?: number[]
  chatStep?: DashboardChatStatusId
}

export interface ScoutRequestAiAssessment {
  sessionId: number
  totalScore: number
  summary: string
  sections: {
    description: string
    maxScore: number
    score: number
    reason: string
  }[]
}

export type ScoutProfileDraftType =
  | 'education'
  | 'career'
  | 'languageSkill'
  | 'introduction'
  | 'expectation'
  | 'jobSearchingReason'

export enum ScoutRequestFilterAssessmentSectionsEnum {
  isActivelySeeker = '적극 구직 중',
  hasStartupCareerHistory = '스타트업 근무 경험',
  isEducationCs = '컴퓨터 관련전공',
  hasIntroduction = '자기소개 있음',
  hasAccessibleAttachment = '공개된 첨부 자료 있음',
  isNotWorking = '재직중 제외',
  isRemoteWorkExcluded = '원격근무만 가능 제외',
  isEducationFinished = '졸업/졸업예정',
  jobInterestLevels = '직무 관심 수준',
  workLevels = '경력',
  educationCategories = '최종학력',
  schools = '출신 학교',
  locations = '희망 근무 지역',
  fundingRounds = '인재 선호 투자 단계',
  positions = '포지션',
  languageSkills = '외국어 능력',
  idpCertificates = '보유한 인재 배지',
  techStacks = '스킬',
  serviceAreas = '관심 서비스 분야',
  careerServiceAreas = '경력 서비스 분야',
  experience = '경력 연차',
  salary = '연봉',
  keywords = '키워드',
}

export type ScoutRequestFilterAssessmentSectionKey =
  keyof typeof ScoutRequestFilterAssessmentSectionsEnum

export interface ScoutRequestFilterAssessmentSections {
  isActivelySeeker?: boolean
  hasStartupCareerHistory?: boolean
  isEducationCs?: boolean
  hasIntroduction?: boolean
  hasAccessibleAttachment?: boolean
  isNotWorking?: boolean
  isRemoteWorkExcluded?: boolean
  isEducationFinished?: boolean
  jobInterestLevels?: boolean
  workLevels?: boolean
  educationCategories?: boolean
  schools?: boolean
  locations?: boolean
  fundingRounds?: boolean
  positions?: WithTotalMatched
  languageSkills?: WithTotalMatched
  idpCertificates?: WithTotalMatched
  techStacks?: WithTotalMatched
  serviceAreas?: WithTotalMatched
  careerServiceAreas?: WithTotalMatched
  experience?: boolean
  salary?: boolean
  keywords?: boolean
}

export interface WithTotalMatched {
  total: number
  matched: number
}

export interface ScoutRequestFilterAssessment {
  score: number
  sections: ScoutRequestFilterAssessmentSections
}

export interface ApplicationParams {
  fitReason?: string | null
  joinReason?: string | null
  fulfillments?: ApplicationFulfillmentParams[]
}

export interface ApplicationFulfillmentParams {
  id: number
  description: string | null
  isFit: boolean
}

export interface ResumeAnalysisCharacterMatch {
  score: 0
  jobPostingSummary: string
  conditionFulfillment: {
    condition: string
    detail: string
    fulfillmentIndex: number // 0.0~1.0
  }[]
  positiveFeedback: string
  negativeFeedback: string
}

export interface ResumeAnalysisFactAssault {
  input: {
    businessScale: string
    serviceAreas: string[]
    positionType: string
    experience: number
  }
  output: {
    career: { title: string; content: string }
    performance: { title: string; content: string }
    skills: { title: string; content: string }
    introduction: { title: string; content: string }
    overall: { title: string; content: string }
  }
}

export interface ResumeAnalysisKeywordsMatch {
  resumeKeywords: {
    keyword: string
    importanceIndex: number
  }[]
  resumeSummary: string
  jobPostingKeywords: {
    keyword: string
    importanceIndex: number
  }[]
  jobPostingSummary: string
  companyNeeds: string
  matchingKeywords: string[]
  totalFeedBack: string
}

export type DashboardScoutsRecommendedTabType =
  | 'newCurated'
  | 'waiting'
  | 'accepted'
  | 'rejected'

export type PositionFilterType = keyof StartupPositionFilterParams

export type ScoutSearchSessionEventType =
  | 'SAVE'
  | 'PROPOSE'
  | 'MEMO'
  | 'VIEW_PROFILE_DETAIL'
  | 'CANCEL_AI_ASSESSMENT'
  | 'OPEN_PROFILE_IN_NEW_TAB'

export interface MyCurationStartupPosition {
  id: number
  startup: {
    id: number
    name: string
    thumbnail: string
    startupPosition: {
      id: number
      name: string
      careerType: WithIdName
    }
  }
  positionFitReason: string
  motivationFitReason: string
  criteria: string
}
